<template>
  <div class="row align-items-center">
    <div class="col-12">
      <h3 class="mb-2">{{ $t("landingList") }}</h3>
    </div>
    <div class="col-12 col-sm-6 col-md-8 text-left pb-2 pb-sm-0">
      <base-button
        borderRadiusLeft
        borderRadiusRight
        class="button-height-33 py-0 px-3"
        @click="$router.push({ name: 'NewLanding' });"
      >
        <img class="mr-1" src="/img/icons/stevie/add-shape.svg" alt="Add icon">
        {{ $t("newLandingTemplate") }}
      </base-button>
    </div>
    <div class="col-12 col-sm-6 col-md-4 d-flex justify-content-end pb-2 pb-sm-0">
      <div class="px-1">
        <el-select popper-class="select-no-scroll" class="select-danger select-no-scroll" :placeholder="$t('filterBy')" v-model="categorySelected" @change="listLandings(1)">
          <el-option :value="null" :label="$t('templateCategories.all')" >
          </el-option>
          <el-option  v-for="category in categories" :key="category.id" :value="category.id"
                      :label="$t(`templateCategories.${category.id}`)">
          </el-option>
        </el-select>
      </div>
      <div class="px-1">
        <input
          name="search"
          v-model="search"
          @change="listLandings(1)"
          :placeholder="$t('search')"
          type="text"
          maxLength="255"
          class="input-stevie"
        >
      </div>
    </div>
  </div>

<el-table
  class="table-responsive table-flush border-top-0 min-table"
  :class="landingsData.data.length === 0 && !isLoading ? 'table-empty': ''"
  :data="landingsData.data"
  @cell-click="cellClick"
  row-class-name="cursor-pointer"
>
  <el-table-column prop="image" width="100px" label-class-name="border-top-0 text-prussian-blue">
    <template v-slot="{ row }">
      <div v-if="row.template.image_header.url"  class="d-flex justify-content-end">
        <img alt="" :src="row.template.image_header.url" class="avatar object-fit-cover"/>
      </div>
    </template>
  </el-table-column>

  <el-table-column :label="$t('nameLanding')" :empty-text="''" prop="name" min-width="150px" label-class-name="border-top-0 text-prussian-blue">
    <template v-slot="{ row }">
      <div v-if="row.name" class="media align-items-center">
        <div class="media-body w-100">
          <span class="font-weight-500 name mb-0 text-sm text-dark">
            {{   row.name }}</span>
          <span v-if="row.updated_at" class="font-weight-400 name mb-0 text-sm text-gray ml-1">
            {{   row.updated_at }}</span>
        </div>
      </div>
    </template>
  </el-table-column>

  <el-table-column :label="$t('status')" prop="title" min-width="80px" label-class-name="border-top-0 text-prussian-blue">
    <template v-slot="{ row }">
        <span  class="status font-weight-500 text-dark text-capitalize">{{ $t(`statusList.${status[row.status].name}`) }}</span>
    </template>
  </el-table-column>

  <el-table-column :label="$t('actions')" min-width="130px" label-class-name="border-top-0 text-prussian-blue d-flex justify-content-end" align="right">
    <template v-slot="{ row }">
      <el-tooltip placement="top" :content="$t('clone')">
        <base-button class="btn-default bg-transparent border-0 shadow-none px-0" @click="cloneItem(row.id)">
          <em class="fas fa-clone text-prussian-blue"></em>
        </base-button>
      </el-tooltip>
      <el-tooltip placement="top" :content="$t('preview')">
        <base-button class="btn-default bg-transparent border-0 shadow-none px-0" @click="showLandingTemplate(row)">
          <em class="fas fa-search text-prussian-blue"></em>
        </base-button>
      </el-tooltip>
      <el-tooltip placement="top" :content="$t('delete')">
        <base-button :disabled="row.status === 4" class="btn-default bg-transparent border-0 shadow-none px-0" @click="modalDeleteLanding(row.id)">
          <em class="fas fa-trash text-prussian-blue"></em>
        </base-button>
      </el-tooltip>
    </template>
  </el-table-column>

</el-table>
<div v-if="landingsData.data.length === 0 && !isLoading" class="modal-landing">
  <strong class="title">{{$t('newLanding.title')}}</strong>
  <p class="content">{{$t('newLanding.content')}}</p>
  <base-button
    borderRadiusLeft
    borderRadiusRight
    class="button-landing button-height-33 py-0 px-3"
    @click="$router.push({ name: 'NewLanding' });"
  >
    <img class="mr-1" src="/img/icons/stevie/add-shape.svg" alt="Add icon">
    {{ $t("newLandingTemplate") }}
  </base-button>
</div>

<div v-if="landingsData.data.length > 0" class="card-footer py-4 d-flex justify-content-end">
  <base-pagination :modelValue="landingsData.current_page" @update:modelValue="changePage" :total="landingsData.total" :perPage="landingsData.per_page"></base-pagination>
</div>

<!-- </card> -->
<ConfirmModal
v-if="showConfirmModal"
:showModal="showConfirmModal"
:modal-info="modalConfirm"
:isLoading ="isLoading"
@confirm="modalConfirm.confirmAction()"
@cancel="modalConfirm.cancelAction(null)"
@closeModal="showConfirmModal=false"
></ConfirmModal>
<modal  v-model:show="showPreview" :showClose="true" :is-allowed-close-modal="true" @close="showPreview= false" header-classes="pb-1" body-classes="pt-1" class="modalContainer">
<template v-slot:header>
</template>
<TemplatePreview v-if="templatePreview" :template="templatePreview" :isTemplate="false" ></TemplatePreview>
</modal>

</template>

<script>
const APP_URL = process.env.VUE_APP_URL;

import {
ElTable,
ElTableColumn,
ElDropdownMenu,
ElDropdownItem,
ElDropdown,
ElSelect,
ElOption
} from "element-plus";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useLandings from "../../../composables/useLandings";
import ConfirmModal from "../../Components/ConfirmModal";
import { landingTemplateStatus } from '@/util/Settings';
import modal from '@/components/Modal';
import TemplatePreview from '@/views/Components/TemplatePreview.vue';
import { TemplateFormClass } from '@/util/Models/TemplateClass.js';


export default {
name: "LandingsList",
components: {
[ElTable.name]: ElTable,
[ElTableColumn.name]: ElTableColumn,
[ElDropdown.name]: ElDropdown,
[ElDropdownItem.name]: ElDropdownItem,
[ElDropdownMenu.name]: ElDropdownMenu,
ElSelect,
ElOption,
ConfirmModal,
modal,
TemplatePreview
},
setup() {
const router = useRouter();
const route = useRoute();
const showConfirmModal = ref(false)

const { landingsData, getLandings, cloneLanding, deleteLanding, getCategories, categories }= useLandings();
const landingIdToManage = ref(null)
const modalConfirm = ref({});
const isLoading = ref(false)
const status = ref(landingTemplateStatus);
const templatePreview = ref(null)
const selectedTemplate = ref(null)
const showPreview = ref(false)
const basicUrl = ref(`${APP_URL}/landing/`)
const categorySelected = ref(null);
const search = ref(null);

const changePage = (event)=>{
  if(event !== landingsData.value.current_page){
    listLandings(event)
  }
}

const listLandings = async (page = 1) => {
  let filters = {}
  if (categorySelected.value) {
    filters.category = categorySelected.value
  }
  if (search.value) {
    filters.search = search.value
  }
  await getLandings(page, Object.keys(filters).length === 0 ? null : filters);
}

const listCategories = async () => {
  await getCategories()
}

const editLanding = (id, saveFilter = true) => {
  if(saveFilter){
    localStorage.setItem('landingsFilters', JSON.stringify({page: landingsData.value.current_page}))
  }
  router.push({ name: 'EditLanding', params: { id: id } });
}
const cloneItem = async (id) => {
  const response = await cloneLanding(id)
  if (response && response.status === 200) {
    editLanding(response.data.id, false)
  }
}
const modalDeleteLanding = async (landingId) => {
  if(landingId){

    modalConfirm.value = {
      allowClose: false,
      title:'deleteItem.title',
      subtitle: 'deleteItem.subtitle',
      textCancel: 'no',
      textConfirm: 'yes',
      confirmAction: initDeleteLanding,
      cancelAction: modalDeleteLanding,
    }
    landingIdToManage.value = landingId;
    showConfirmModal.value = true;

  }else{
    modalConfirm.value = {};
    landingIdToManage.value = null;
    showConfirmModal.value = false;
  }
}

const initDeleteLanding = async () => {
  isLoading.value= true;
  const response = await deleteLanding(landingIdToManage.value)
  if(response && response.status === 200){
    listLandings(landingsData.value.current_page)
  }
  isLoading.value= false;
  modalDeleteLanding(null)

}

const cellClick = ({id: landingId}, { property: columnName }) => {
  columnName && editLanding(landingId);
}

const showLandingTemplate = (template) => {
  if(template){
    showPreview.value = true
    templatePreview.value = new TemplateFormClass(template)
  }
  selectedTemplate.value = template ? template : null;
}

onMounted(async()=>{
  const { page } = route.query;
  landingsData.value.current_page = page ? page : 1;
  if(page){
    router.replace({...router.currentRoute, query: {}})
  }
    isLoading.value = true;
    await listLandings(landingsData.value.current_page)
    await listCategories()
    isLoading.value = false;
})

return {
  landingsData,
  showConfirmModal,
  modalConfirm,
  isLoading,
  status,
  editLanding,
  modalDeleteLanding,
  cloneItem,
  initDeleteLanding,
  listLandings,
  changePage,
  showLandingTemplate,
  templatePreview,
  selectedTemplate,
  showPreview,
  basicUrl,
  categorySelected,
  categories,
  search,
  cellClick,
};
},
}
</script>

<style lang="scss">
.modalContainer {
  .modal-dialog {
    max-width: 80%!important;
  }
}
</style>

<style scoped lang="scss">
.el-table_1_column_4 th {
text-align: right;
}
:deep() .el-table__body-wrapper{
min-height: 350px!important;
}
.min-table{
min-height: 450px;
max-width: 99%!important;
}
.dropdown-item{
height: 33px;
display: flex;
justify-content: flex-start;
align-items: center;
&:hover{
color: #16181b!important;
background-color: #f6f9fc!important;
}
}
:deep() .el-table__empty-block {
display: none!important;
}
.table-empty{
  position: absolute;
}
.modal-landing{
  position: relative;
  background-color: #8dfd36;
  border-radius: 20px;
  padding: 24px;
  max-width: 299px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #012454;
  margin: auto;
  margin-top: 86px;


  .title{
    padding-bottom: 22px ;
    font-size: 15px;
    font-weight: 600;
  }
  .content{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 9px;
  }
  .button-landing{
    min-height: 33px;
    height: auto;
  }
}
.text-link{
  font-size: 13px;
  color: #0373a8;
}

</style>
